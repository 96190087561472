import './js/shim/shim-jquery';
import './js/shim/shim-lightbox';
import './js/shim/shim-semantic-ui';

import 'semantic-ui-css/semantic.css';
import 'lightbox2/dist/css/lightbox.min.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'owl.carousel/dist/owl.carousel'

import '../../../vendor/sylius/bootstrap-theme/assets/js/index';
import './scss/index.scss';
import '../../../vendor/sylius/bootstrap-theme/assets/media/sylius-logo.png';
import '../../../vendor/sylius/bootstrap-theme/assets/js/fontawesome';
import './scripts';
